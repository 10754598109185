import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import cheerio from "cheerio";
import { Link as ScrollLink } from "react-scroll";

import { Layout } from "../components";
import Seo from "../components/Seo";

const SingleBlogPage = ({ data, pageContext }) => {
  const blog = data.microcmsBlog;
  const { previous, next } = pageContext;

  const $ = cheerio.load(data.microcmsBlog.content);
  $(`content`).html(); // fix for cheerio v1
  const headings = $("h2").toArray();
  const toc = headings.map((data) => ({
    text: data.children[0].data,
    id: data.attribs.id,
    name: data.name,
  }));

  const [openToc, setOpenToc] = useState(false);
  return (
    <Layout>
      <Seo
        title={blog.title}
        description={blog.description}
        keywords={blog.keywords}
        image={blog.featuredImage.url}
      />
      <section className="px-2 md:px-8 xl:px-0 max-w-5xl mx-auto mb-24 md:mb-32 xl:mb-40">
        <div className="mt-32 mb-16 lg:mt-40 lg:mb-24 ">
          <span className="block text-xl font-bold tracking-widest text-center lg:text-2xl">
            横丁ブログ
          </span>
        </div>
        <div className="mb-16 bg-white box-shadow py-12 px-2 md:px-8 lg:px-0 rounded-blog bg-opacity-80 md:pt-16 lg:pt-20">
          <div className="w-full mb-12 lg:mb-16">
            <h1 className="text-xl text-center font-bold tracking-wide lg:text-2xl mb-6 lg:mb-12">
              {blog.title}
            </h1>
            <div className="flex items-center justify-center gap-x-6 mb-6 lg:mb-12">
              <span
                className={`${
                  blog.category[0].id === "media"
                    ? "bg-primary-blue"
                    : `${
                        blog.category[0].id === "blog"
                          ? "bg-primary-orange"
                          : "bg-primary-purple"
                      }`
                } rounded-full py-1 px-4 text-xs text-white font-medium`}
              >
                {blog.category[0].name}
              </span>
              <time
                dateTime={blog.createdAt}
                className="text-xs tracking-wider text-gray-700 font-medium"
              >
                {blog.date}
              </time>
              <div className="flex items-center gap-x-1 text-xs font-bold">
                <svg
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="10"
                    r="3"
                    stroke="#c4c4c4"
                    stroke-linecap="round"
                    stroke-width="2"
                  />
                  <circle
                    cx="12"
                    cy="12"
                    r="9"
                    stroke="#c4c4c4"
                    stroke-width="2"
                  />
                  <path
                    d="m17.7805 18.8264c.1271-.0698.1873-.2209.1335-.3556-.3856-.9663-1.1284-1.8174-2.1326-2.4376-1.0848-.67-2.414-1.0332-3.7814-1.0332s-2.69659.3632-3.78142 1.0332c-1.00414.6202-1.74698 1.4713-2.1326 2.4376-.05375.1347.00638.2858.1335.3556 3.60023 1.9766 7.96082 1.9766 11.56102 0z"
                    fill="#c4c4c4"
                  />
                </svg>
                <span className="text-gray-500">{blog.author.name}</span>
              </div>
            </div>
            <div className="lg:px-16 w-full h-48 md:h-96 lg:h-120 mb-16 lg:mb-24">
              <img
                className="w-full h-full object-cover rounded-blog"
                src={blog.featuredImage.url}
                alt={`${blog.title} 画像`}
              />
            </div>
            <div className="px-2 max-w-3xl mx-auto mb-16">
              {blog.category[0].id !== "news" ? (
                <div className="border rounded-lg p-4 mb-16">
                  <div className="flex items-center justify-center gap-x-2">
                    <p className="text-lg text-center font-bold">目次</p>
                    <span>
                      [
                      <button
                        onClick={() => setOpenToc(!openToc)}
                        className="text-xs text-blue-400 font-medium"
                      >
                        {openToc ? "表示" : "非表示"}
                      </button>
                      ]
                    </span>
                  </div>
                  <ul className="toc">
                    {toc.map((toc) => {
                      if (!openToc) {
                        return (
                          <li
                            className="border-b py-2 lg:py-4 tob flex items-center justify-between"
                            key={toc.id}
                          >
                            <ScrollLink
                              className="cursor-pointer text-primary-gray tracking-wider leading-loose"
                              to={toc.id}
                              smooth
                              offset={-100}
                              duration={300}
                            >
                              {toc.text}
                            </ScrollLink>
                          </li>
                        );
                      } else {
                        return;
                      }
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
              <div
                className="blog tracking-wider leading-loose"
                dangerouslySetInnerHTML={{
                  __html: `${data.microcmsBlog.content}`,
                }}
              />
            </div>
            <div className="lg:flex gap-x-12 justify-center bg-gray-100 p-4 mx-4 max-w-3xl lg:mx-auto lg:py-8 lg:px-12">
              <img
                className="mx-auto w-20 h-20 rounded-full lg:w-40 lg:h-40"
                src={blog.author.thumbnail.url}
                alt={`${blog.author.handleName} 画像`}
              />
              <div className="mt-4">
                <span className="block font-bold text-center lg:text-left lg:text-xl">
                  {blog.author.handleName}
                </span>
                <div
                  className="mt-4 text-sm tracking-wider leading-relaxed"
                  dangerouslySetInnerHTML={{
                    __html: `${blog.author.bio}`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <ul className="grid grid-cols-3 lg:grid-cols-7 items-center justify-center gap-x-2 mb-8 px-2">
          <li className="lg:col-start-3">
            {previous && (
              <Link
                className="text-sm font-bold text-primary-gray lg:text-base"
                to={"../" + previous.blogId}
                rel="prev"
              >
                <div className="group flex items-center justify-center gap-x-2">
                  <svg
                    className="fill-current text-blue-500 w-4 transform rotate-180 duration-300 group-hover:scale-125 group-hover:-translate-x-1.5"
                    viewBox="0 0 144 106"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m99.058 101.356 39.871-39.8707c4.686-4.6863 4.686-12.2843 0-16.9706l-39.871-39.8709c-3.898-3.898026-10.218-3.898037-14.116-.00001-3.8929 3.89291-3.8988 10.20271-.0131 14.10291l24.1631 24.2533h-98.092c-5.52285 0-10 4.4772-10 10s4.47715 10 10 10h98.092l-24.1631 24.2533c-3.8857 3.9002-3.8798 10.21.0131 14.1027 3.898 3.898 10.218 3.898 14.116 0z"
                      stroke-width="2"
                    />
                  </svg>
                  <span className="transform duration-300 group-hover:text-blue-500">
                    前の記事
                  </span>
                </div>
              </Link>
            )}
          </li>
          <li>
            <Link to="/blog">
              <svg
                className="fill-current text-blue-500 w-6 mx-auto transform duration-300 hover:scale-125"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m14 16c-1.1046 0-2-.8954-2-2s.8954-2 2-2 2 .8954 2 2-.8954 2-2 2zm-6 0c-1.10457 0-2-.8954-2-2s.89543-2 2-2 2 .8954 2 2-.89543 2-2 2zm-6 0c-1.10457 0-2-.8954-2-2s.89543-2 2-2 2 .8954 2 2-.89543 2-2 2zm12-6c-1.1046 0-2-.89543-2-2s.8954-2 2-2 2 .89543 2 2c0 .53043-.2107 1.03914-.5858 1.41421-.3751.37508-.8838.58579-1.4142.58579zm-6 0c-1.10457 0-2-.89543-2-2s.89543-2 2-2 2 .89543 2 2c0 .53043-.21071 1.03914-.58579 1.41421-.37507.37508-.88378.58579-1.41421.58579zm-6 0c-1.10457 0-2-.89543-2-2s.89543-2 2-2 2 .89543 2 2c0 .53043-.21071 1.03914-.58579 1.41421-.37507.37508-.88378.58579-1.41421.58579zm12-6c-1.1046 0-2-.89543-2-2s.8954-2 2-2 2 .89543 2 2c0 .53043-.2107 1.03914-.5858 1.41421-.3751.37508-.8838.58579-1.4142.58579zm-6 0c-1.10457 0-2-.89543-2-2s.89543-2 2-2 2 .89543 2 2c0 .53043-.21071 1.03914-.58579 1.41421-.37507.37508-.88378.58579-1.41421.58579zm-6 0c-1.10457 0-2-.89543-2-2s.89543-2 2-2 2 .89543 2 2c0 .53043-.21071 1.03914-.58579 1.41421-.37507.37508-.88378.58579-1.41421.58579z" />
              </svg>
            </Link>
          </li>
          <li>
            {next && (
              <Link
                className="text-sm font-bold text-primary-gray lg:text-base"
                to={"../" + next.blogId}
                rel="next"
              >
                <div className="group flex items-center justify-center gap-x-2">
                  <span className="transform duration-300 group-hover:text-blue-500">
                    次の記事
                  </span>
                  <svg
                    className="fill-current text-blue-500 w-4 transform duration-300 group-hover:scale-125 group-hover:translate-x-1.5"
                    viewBox="0 0 144 106"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m99.058 101.356 39.871-39.8707c4.686-4.6863 4.686-12.2843 0-16.9706l-39.871-39.8709c-3.898-3.898026-10.218-3.898037-14.116-.00001-3.8929 3.89291-3.8988 10.20271-.0131 14.10291l24.1631 24.2533h-98.092c-5.52285 0-10 4.4772-10 10s4.47715 10 10 10h98.092l-24.1631 24.2533c-3.8857 3.9002-3.8798 10.21.0131 14.1027 3.898 3.898 10.218 3.898 14.116 0z"
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </Link>
            )}
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default SingleBlogPage;

export const query = graphql`
  query($blogId: String!) {
    microcmsBlog(blogId: { eq: $blogId }) {
      author {
        thumbnail {
          url
        }
        id
        handleName
        name
        bio
      }
      blogId
      featuredImage {
        url
      }
      date(formatString: "Y年MM月DD日")
      category {
        id
        name
      }
      title
      content
      keywords
      description
      createdAt(formatString: "Y-MM-DD")
    }
  }
`;
